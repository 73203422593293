<template>
  <div class="container d-flex mt-8 pb-8">
    <div class="row">
      <div class="col-12 col-md-3 p-0 pr-md-3 mb-5 mb-md-0">
        <div class="bg-white input-group input-group-lg rounded px-2 py-5">
          <div class="col-12 d-flex justify-content-between">
            <h4 class="mb-0" v-dynamic-content:b2b_locations_list_filter_label></h4>
            <button
              v-if="filterHasChanged"
              type="button"
              class="bg-white border-0 text-primary"
              @click="resetFilter"
            >
              Reset
            </button>
          </div>
          <div class="col-12 my-5 search-container">
            <input
              type="text"
              placeholder="Zoek op adres..."
              class="form-control search-input"
              v-model.trim.lazy="filterInput.addressLike"
            />

            <div class="clear-search" v-show="filterInput.addressLike">
              <button @click="clearSearch"></button>
            </div>
          </div>
          <div class="col-12 my-3">
            <fieldset>
              <legend class="legend">Meter status:</legend>
              <div>
                <div v-for="(option, i) in locationStatusOptions" :key="i + 's'">
                  <div class="">
                    <label class="form-control checkbox-label mb-0 pl-0" :for="option">
                      <input
                        :id="option"
                        :value="option"
                        v-model="filterInput.locationStatusList"
                        type="checkbox"
                      />
                      {{ option }}
                    </label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-12 my-3">
            <label class="mb-3">Complex:</label>
            <customer-reference-selector
              v-model="filterInput.customerReference"
              :customerReferences="customerReferences"
            ></customer-reference-selector>
          </div>
          <div class="col-12 my-3">
            <label class="mb-3">Geïnstalleerd door:</label>
            <select class="custom-select" v-model="filterInput.installedBySungevity">
              <option value="">Alle</option>
              <option value="true">Sungevity</option>
              <option value="false">Overig</option>
            </select>
          </div>
        </div>
      </div>

      <div class="card col-12 col-md-9 overflow-auto">
        <div
          class="d-flex row justify-content-between align-items-center"
          :class="{ loading }"
          v-if="getLocations.nodes"
        >
          <div
            v-dynamic-content:b2b_locations_header_locations_found="[totalLocationsFormatted]"
            class="col-auto"
          ></div>
          <div class="col-auto">
            <button
              class="btn btn-primary export d-flex"
              @click="exportToCsv"
              :disabled="getLocations.totalCount < 1"
            >
              <b-spinner class="flex spinner-border-sm mr-2" v-if="loadingExport"></b-spinner>
              <span
                v-dynamic-content:b2b_locations_list_export_button="[totalLocationsFormatted]"
              ></span>
            </button>
          </div>
        </div>

        <div class="table-container">
          <b-spinner v-if="loading"></b-spinner>

          <table :class="{ loading }" class="mt-6 mb-6 table-hover text-nowrap">
            <thead>
              <tr>
                <th width="25%" v-dynamic-content:b2b_locations_table_column_address></th>
                <th width="22%" v-dynamic-content:b2b_locations_table_column_city></th>
                <th width="15%" v-dynamic-content:b2b_locations_table_column_complex_number></th>
                <th width="13%">
                  <span v-dynamic-content:b2b_locations_table_column_sunsure_progress></span>
                  <a
                    class="info-symbol ml-1"
                    role="button"
                    data-info-popup="sunsureProgressInfoPopup"
                  >
                    info
                  </a>
                </th>
                <th width="10%" v-dynamic-content:b2b_locations_table_column_status></th>
                <th width="10%" v-dynamic-content:b2b_locations_table_column_online_since></th>
              </tr>
            </thead>
            <tbody v-if="getLocations.nodes">
              <tr v-for="location in getLocations.nodes" :key="location.id" @click="goTo(location)">
                <td>{{ location.address }}</td>
                <td>{{ location.city }}</td>
                <td>{{ location.customerReference }}</td>
                <td>
                  <div
                    :title="location.sunsureProgress && `${location.sunsureProgress.percentage}%`"
                  >
                    <sun-star
                      :class="{ active }"
                      class="star"
                      v-for="(active, index) in starsFor(location)"
                      :key="index"
                    ></sun-star>
                  </div>
                </td>
                <td>
                  <div
                    v-if="!location.meteringSystems || location.meteringSystems.length === 0"
                    class="d-flex"
                  >
                    <div class="inactive-icon"></div>
                    <div>geen meter</div>
                  </div>
                  <metering-system-status
                    v-else
                    v-for="(ms, index) in location.meteringSystems"
                    :meteringSystem="ms"
                    :key="index"
                  ></metering-system-status>
                </td>
                <td>{{ formatOnlineSince(location.onlineSince) }}</td>
              </tr>
            </tbody>
          </table>

          <pagination-buttons
            :numberOfPages="numberOfPages"
            :loading="loading"
          ></pagination-buttons>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { formatNumber } from '@/app/filters/formatNumbers'
import gql from 'graphql-tag'
import MeteringSystemStatus from './MeteringSystemStatus'
import CustomerReferenceSelector from '@/app/components/b2b/common/CustomerReferenceSelector'
import PaginationButtons from '@/app/components/b2b/common/PaginationButtons'
import SunStar from '@/assets/components/sun-star.svg'
import { equals } from 'rambda'

export function range(n) {
  return [...Array(n).keys()]
}

const locationStatusOptions = ['Online', 'Offline', 'Geen medewerking / meter']

const emptyFilter = {
  addressLike: '',
  locationStatusList: locationStatusOptions,
  customerReference: null,
  installedBySungevity: '',
}

const locationsPerPage = 30

const filterStorageKey = 'MYSUN_B2B_CURRENT_FILTER_V1'
const storage = window.sessionStorage
function storeFilter(filter) {
  const data = JSON.stringify(filter)
  storage && storage.setItem(filterStorageKey, data)
}
function getStoredFilter() {
  const result = storage && storage.getItem(filterStorageKey)
  return JSON.parse(result)
}

export default {
  components: {
    MeteringSystemStatus,
    CustomerReferenceSelector,
    PaginationButtons,
    SunStar,
  },
  data() {
    return {
      getLocations: 'not_set',
      loadingExport: false,
      locationStatusOptions,
      filterInput: {
        customerReference: null,
        locationStatusList: locationStatusOptions,
        addressLike: '',
        installedBySungevity: '',
        ...getStoredFilter(),
      },
    }
  },
  apollo: {
    getLocations: {
      query: gql`
        query getLocations($filter: LocationsFilter, $limit: Int!, $offset: Int!) {
          getLocations(filter: $filter, limit: $limit, offset: $offset) {
            totalCount
            nodes {
              id
              address
              city
              onlineSince
              customerReference
              solarModule {
                numberOfModules
                nameOfModule
              }
              meteringSystems {
                status
                inactive {
                  type
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          filter: this.filter,
          limit: locationsPerPage,
          offset: (this.page - 1) * locationsPerPage,
        }
      },
      skip() {
        return !this.locationGroupId
      },
    },
  },
  methods: {
    mapToLocationStatusList(statuses) {
      // this is not pretty but a temporarily solution before we start using the real offline statuses
      const locationStatusList = []
      if (statuses.includes('Online')) locationStatusList.push('Functioning')
      if (statuses.includes('Geen medewerking / meter')) locationStatusList.push('Inactive')
      if (statuses.includes('Offline'))
        locationStatusList.push(
          'Never connected',
          'Recently disconnected',
          'Zero production measured from beginning',
          'Zero production measured recently',
          'Faulty production measured recently'
        )
      return locationStatusList
    },
    starsFor(location) {
      if (location.sunsureProgress == null) return []
      const { percentage } = location.sunsureProgress

      if (percentage >= 100) return [1, 1, 1]
      else if (percentage >= 90) return [1, 1, 0]
      else return [1, 0, 0]
    },
    clearSearch() {
      this.filterInput.addressLike = ''
    },
    resetFilter() {
      this.filterInput = { ...emptyFilter }
    },
    goTo(location) {
      this.$router.push({
        name: 'B2BLocationDetails',
        params: { id: location.id },
        query: { page: this.page },
      })
    },
    goToPage(page) {
      this.$router.push({ name: 'B2BLocationList', query: { page } })
    },
    formatOnlineSince(date) {
      if (!date) return
      return DateTime.fromISO(date).toFormat('dd-MM-y')
    },
    formatInteger(n) {
      return formatNumber(n)
    },
    async exportToCsv() {
      // would be great handle loading via Apollo, but I haven't found a way so far...
      this.loadingExport = true
      const {
        data: { exportLocationsToCsv: result },
      } = await this.$apollo.mutate({
        mutation: gql`
          mutation exportLocationsToCsv($filter: LocationsFilter) {
            exportLocationsToCsv(filter: $filter) {
              success
              signedUrl
              message
            }
          }
        `,
        variables: {
          filter: this.filter,
        },
      })
      this.loadingExport = false

      if (result.success) {
        location.href = result.signedUrl
      } else {
        alert(`An error occured: ${result.message}`)
      }
    },
  },
  computed: {
    page() {
      // we use human-friendly starting-at-1 page numbers in the URL
      return Number(this.$route.query.page) || 1
    },
    customerReferences() {
      return this.$store.getters.locationGroup.customerReferences || []
    },
    filter() {
      const {
        addressLike,
        locationStatusList,
        customerReference,
        installedBySungevity,
      } = this.filterInput
      const filter = {
        locationGroupId: this.locationGroupId,
        addressLike: addressLike || null,
        locationStatusList: this.mapToLocationStatusList(locationStatusList),
        installedBySungevity: installedBySungevity || null,
        customerReference,
      }

      return filter
    },
    filterHasChanged() {
      const filterInput = { ...this.filterInput }
      // we need to standardize the order of the location status list otherwise the equals doesn't match with the empty filter value
      filterInput.locationStatusList = filterInput.locationStatusList.sort(
        (a, b) =>
          locationStatusOptions.findIndex((s) => s === a) -
          locationStatusOptions.findIndex((s) => s === b)
      )
      return !equals(filterInput, emptyFilter)
    },
    loading() {
      return this.$apollo.loading
    },
    totalLocationsFormatted() {
      return this.formatInteger(this.getLocations.totalCount)
    },
    numberOfPages() {
      return Math.ceil(this.getLocations.totalCount / locationsPerPage)
    },
    locationGroupId() {
      return this.$store.getters.locationGroup.id
    },
  },
  watch: {
    customerReferences(list) {
      // if the filter is somehow not an existing one, clear it
      if (list.length && !list.includes(this.filterInput.customerReference)) {
        this.filterInput.customerReference = null
      }
    },
    filter: {
      deep: true,
      handler() {
        storeFilter(this.filterInput)
        if (this.page !== 1) this.goToPage(1)
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchLocationGroup')
    this.$store.commit('SET_HEADER_TEXT', '')
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
}
table {
  width: 100%;
}

td,
th {
  padding: 0.4em;
}
thead {
  font-size: 1em;
  font-weight: 500;
}
tbody td {
  font-size: 0.8em;
  border-top: 1px solid #dddddd;
}
.loading {
  opacity: 0.3;
}
.table-container {
  position: relative;
}
.table-container .spinner-border {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  z-index: 10;
}

.spinner-border {
  color: $brand-color;
}
button .spinner-border {
  color: $chart-grey;
}

td {
  cursor: pointer;
}

.legend {
  font-size: 1.125rem;
}

.search-input {
  background-image: url('../../../../assets/icons/search.svg');
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: 0.1em center;
  padding-left: 2.2em;
  background-color: white;
}

.search-container {
  position: relative;
  width: 100%;
}

.clear-search {
  position: absolute;
  top: 0;
  left: 95%;
  margin-left: -1.5em;
  height: 100%;
  width: 14px;
}

.clear-search button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: url('../../../../assets/icons/circle-x.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 100%;
  border: 0px;
  height: 100%;
  width: 0.8em;
}

.star {
  fill: #d2e3e3;
  margin-right: 4px;
}
.star.active {
  fill: $brand-color;
}

.info-symbol {
  overflow: hidden;
  text-indent: -9999px;
  display: inline-block;
  background: url('../../../../assets/icons/info-symbol.svg') no-repeat;
  width: 18px;
  height: 18px;
  margin: 0;
  padding-top: 6px;

  &:hover {
    background: url('../../../../assets/icons/info-symbol-hover.svg') no-repeat;
    background-size: 18px;
  }
}

.form-control.checkbox-label {
  background-color: white;
  font-family: system-ui, sans-serif;
  font-size: 1.15rem;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.75em;
  border-color: white;
}

.inactive-icon {
  width: 0.5em;
  height: 0.5em;
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 0.5em;
  background-color: #fde68a;
}
</style>
